.popular-post-image {
    width: 115px;
    position: relative;
    /* height: 100%; */
}

.popular-post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: var(--transition);
}


.popular-post-image:hover .popular-post-img {
    transform: scale(1.1);
}

@media screen and (max-width:578px) {
    .popular-post-image {
        width: 100%;
    }
}