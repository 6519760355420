.search-post-image {
    width: 100%;
    position: relative;
    height: 40vh;
}

.search-post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: var(--transition);
}


.search-post-image:hover .search-post-img {
    transform: scale(1.1);
}