.following-card{
    min-height: 12rem;
}

.following-icon{
    width: 6rem;
    height: 2.2rem;

}

.bg-color-ins{
    /* background-color: #df3276; */
    background: linear-gradient(15deg,#ffb13d,#dd277b,#4d5ed4);
}

.bg-color-tel{
    background-color: #179cde;
}

.bg-color-fb{
    background-color: #1ca8fc;
}

.bg-color-in{
    background-color: #0077b5;
}

.bg-color-tw{
    background-color: #00acee;
}

.bg-color-yt{
    background-color: #f50000;
}

