.bg-clr-footer {
    background-color: #111117 !important;
    /* background-color: rgb(245, 255, 255) !important; */
    box-shadow: var(--shadow-1);
}

.copy-right-bg {
    /* background-color: rgb(231, 255, 255) !important; */
    background-color: #000 !important;
    /* background-color: var(--clr-dark) !important; */
}

.social-icon,
.icon {
    transition: var(--transition);
}


.social-media-content .social-icon {
    margin-top: .4rem;
    background-color: #3c3c3c;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}


.social-icon:hover {
    background-color: var(--clr-light);
}

.social-icon:hover .icon {
    color: var(--clr-blue) !important;
}