/* ComingSoon.css */

.error-container {
    /* background-image: url('../../../assets/images/soon/soon-2.jpg'); */
    /* background-image: url('../../../assets/images/soon/soon-4.jpg'); */
    /* background-image: url('../../../assets/images/soon/soon.jpg'); */
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
}

.error-overlay {
    /* background-color: var(--clr-dark); */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* opacity: .8; */
    background: rgba(0, 0, 0, 0.55);
    box-shadow: 0 8px 32px 0 rgb(31, 38, 151);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.error-container>.text-center {
    position: absolute;
    top: 70%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);

}

.error-container>.text-center>h1 {
    font-size: 2.8rem;
    letter-spacing: 3px;
}

.error-logo {
    left: 0;
    top: 0;
    z-index: 9999;
    position: relative;

}

.error-img {
    width: 25rem;
    position: relative;
    top: -10%;
    /* transform: rotate(6deg); */
}

/* @media screen and (max-width:992px) {
    .error-img {
        width: 35%;
    }
}

@media screen and (max-width:578px) {
    .error-img {
        width: 60%;
    }

    .error-container>.text-center {
        top: 28%;
    }
} */

.btn-error {
    transition: .4s;
    height: 3.8rem;
    width: 25rem;
    padding: 14px 40px;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px dashed var(--clr-blue);
    background-color: #fff;
    color: var(--clr-blue);
    text-decoration: none;
}

.btn-error span:last-child {
    display: none;
}

.btn-error:hover {
    background-color: var(--clr-blue) !important;
    color: #fff !important;
    border: 2px dashed var(--clr-blue);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}