.input-box {
  position: relative !important;
  width: 100% !important;
  transition: 0.5s !important;
}

.input-box input,
.input-box textarea {
  width: 100% !important;
  padding: 11px 10px 7px 10px !important;
  border: 1px solid rgb(0 0 0 / 14%) !important;
  background: #ffffff1a !important;
  border-radius: 5px !important;
  outline: none !important;
  color: var(--clr-dark) !important;
  font-size: 16px !important;
  box-shadow: none !important;
  opacity: 1;
  font-family: var(--font-family-1) !important;
  font-weight: 600;
}

/* .input-box textarea {
  height: 10rem !important;
} */

.input-box span {
  position: absolute !important;
  right: 20px !important;
  padding: 10px !important;
  pointer-events: none !important;
  font-size: 15px !important;
  color: var(--clr-dark) !important;
  /* color: var(--ligh-dark-2) !important; */
  margin: 1px 0 0 !important;
  transition: 0.5s !important;
  opacity: 0.5 !important;
  letter-spacing: .7px !important;
  font-family: var(--font-family-1) !important;
}

.input-box .input-box-input:valid~span,
.input-box input:focus~span,
.input-box textarea:focus~span {
  color: var(--clr-blue) !important;
  /* color: #146C94 !important; */
  transform: translateX(10px) translateY(-12px) !important;
  text-transform: uppercase !important;
  padding: 0 7px !important;
  background: #fff !important;
  letter-spacing: 0.2em !important;
  z-index: 9999;
  box-shadow: none !important;
  opacity: 1 !important;
  font-size: 12px !important;
  font-weight: 800;
  margin: 4px 0 0 0 !important;
}


.input-box .input-box-input:valid,
.input-box input:focus,
.input-box textarea:focus {
  border: 2px solid var(--clr-blue) !important;
  /* border: 2px solid #146C94 !important; */
}

.form-label {
  font-family: var(--font-family-1) !important;
  opacity: 0.99 !important;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--clr-dark) !important;
}

.label-display {
  display: none !important;
  transition: all .7s !important;
}