$percentStep: 1%;
$remStep: 1rem;
$pxStep: 1px;
$step: 1;

$colors-list: (
  "light",
  "dark",
  "purple",
  "pink",
  "yellow",
  "orange",
  "cream",
  "blue",
  "red",
  "gold",
  "primary",
  "success",
  "warning",
  "danger",
  "info"
);

$scale-list: (
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9"
);

$object-fit-values: (
  "fill",
  "contain",
  "cover",
  "none",
  "scale-down"
);


// ------------------------
// width
// ------------------------
@for $i from 0 through 1000 {
  .w-#{$i} {
    width: $i * $percentStep !important;
  }

  .w-#{$i}px {
    width: $i * $pxStep !important;
  }

  .w-#{$i}rem {
    width: $i * $remStep !important;
  }
}


// ------------------------
// max width
// ------------------------
@for $i from 0 through 1000 {
  .max-w-#{$i} {
    max-width: $i * $percentStep !important;
  }

  .max-w-#{$i}px {
    max-width: $i * $pxStep !important;
  }

  .max-w-#{$i}rem {
    max-width: $i * $remStep !important;
  }
}


// ------------------------
// min width
// ------------------------
@for $i from 0 through 1000 {
  .min-w-#{$i} {
    min-width: $i * $percentStep !important;
  }

  .min-w-#{$i}px {
    min-width: $i * $pxStep !important;
  }

  .min-w-#{$i}rem {
    min-width: $i * $remStep !important;
  }
}


// ------------------------
// height
// ------------------------
@for $i from 0 through 1000 {
  .h-#{$i} {
    height: $i * $percentStep !important;
  }

  .h-#{$i}px {
    height: $i * $pxStep !important;
  }

  .h-#{$i}rem {
    height: $i * $remStep !important;
  }
}


// ------------------------
// max height
// ------------------------
@for $i from 0 through 1000 {
  .max-h-#{$i} {
    max-height: $i * $percentStep !important;
  }

  .max-h-#{$i}px {
    max-height: $i * $pxStep !important;
  }

  .max-h-#{$i}rem {
    max-height: $i * $remStep !important;
  }
}


// ------------------------
// min height
// ------------------------
@for $i from 0 through 1000 {
  .min-h-#{$i} {
    min-height: $i * $percentStep !important;
  }

  .min-h-#{$i}px {
    min-height: $i * $pxStep !important;
  }

  .min-h-#{$i}rem {
    min-height: $i * $remStep !important;
  }
}

// ------------------------
// margin
// ------------------------
@for $i from 0 through 1000 {
  .m-#{$i}px {
    margin: $i * $pxStep !important;
  }

  .m-#{$i}rem {
    margin: $i * $remStep !important;
  }

  .m-n-#{$i}rem {
    margin: -$i * $remStep !important;
  }

  .m-n-#{$i}px {
    margin: -$i * $pxStep !important;
  }
}

// ------------------------
// margin rem
// ------------------------
@for $i from 0 through 1000 {
  .mt-#{$i}rem {
    margin-top: $i * $remStep !important;
  }

  .me-#{$i}rem {
    margin-right: $i * $remStep !important;
  }

  .mb-#{$i}rem {
    margin-bottom: $i * $remStep !important;
  }

  .ms-#{$i}rem {
    margin-left: $i * $remStep !important;
  }
}


// ------------------------
// margin px
// ------------------------
@for $i from 0 through 1000 {
  .mt-#{$i}px {
    margin-top: $i * $pxStep !important;
  }

  .me-#{$i}px {
    margin-right: $i * $pxStep !important;
  }

  .mb-#{$i}px {
    margin-bottom: $i * $pxStep !important;
  }

  .ms-#{$i}px {
    margin-left: $i * $pxStep !important;
  }
}


// ------------------------
// margin -X-
// ------------------------
@for $i from 0 through 1000 {
  .mx-#{$i}px {
    margin-left: $i * $pxStep !important;
    margin-right: $i * $pxStep !important;
  }

  .mx-#{$i}rem {
    margin-left: $i * $remStep !important;
    margin-right: $i * $remStep !important;
  }
}


// ------------------------
// margin -Y-
// ------------------------
@for $i from 0 through 1000 {
  .my-#{$i}px {
    margin-top: $i * $pxStep !important;
    margin-bottom: $i * $pxStep !important;
  }

  .my-#{$i}rem {
    margin-top: $i * $remStep !important;
    margin-bottom: $i * $remStep !important;
  }
}


// ------------------------
// negative margin px
// ------------------------
@for $i from 0 through 1000 {
  .mt-n-#{$i}px {
    margin-top: -$i * $pxStep !important;
  }

  .me-n-#{$i}px {
    margin-right: -$i * $pxStep !important;
  }

  .mb-n-#{$i}px {
    margin-bottom: -$i * $pxStep !important;
  }

  .ms-n-#{$i}px {
    margin-left: -$i * $pxStep !important;
  }
}


// ------------------------
// negative margin rem
// ------------------------
@for $i from 0 through 1000 {
  .mt-n-#{$i}rem {
    margin-top: -$i * $remStep !important;
  }

  .me-n-#{$i}rem {
    margin-right: -$i * $remStep !important;
  }

  .mb-n-#{$i}rem {
    margin-bottom: -$i * $remStep !important;
  }

  .ms-n-#{$i}rem {
    margin-left: -$i * $remStep !important;
  }
}


// ------------------------
// margin px | float
// ------------------------
// @for $i from 0 through 1000 {
//   $decimalPart: $i / 10;

//   .mt-#{$i}-#{$decimalPart}px {
//     margin-top: $i + $decimalPart * $pxStep !important;
//   }

//   .me-#{$i}-#{$decimalPart}px {
//     margin-right: $i + $decimalPart * $pxStep !important;
//   }

//   .mb-#{$i}-#{$decimalPart}px {
//     margin-bottom: $i + $decimalPart * $pxStep !important;
//   }

//   .ms-#{$i}-#{$decimalPart}px {
//     margin-left: $i + $decimalPart * $pxStep !important;
//   }
// }
// @for $i from 0 through 1000 {
//   $decimalPart: $i / 10;
//   $marginValue: $i + $decimalPart;

//   .mt-f-#{$marginValue}px {
//     margin-top: #{$marginValue * $pxStep} !important;
//   }

//   .me-f-#{$marginValue}px {
//     margin-right: #{$marginValue * $pxStep} !important;
//   }

//   .mb-f-#{$marginValue}px {
//     margin-bottom: #{$marginValue * $pxStep} !important;
//   }

//   .ms-f-#{$marginValue}px {
//     margin-left: #{$marginValue * $pxStep} !important;
//   }
// }



// ------------------------
// margin rem | float
// ------------------------
@for $i from 0 through 1000 {
  $decimalPart: $i / 10;

  .mt-#{$i}-#{$decimalPart}rem {
    margin-top: $i + $decimalPart * $remStep !important;
  }

  .me-#{$i}-#{$decimalPart}rem {
    margin-right: $i + $decimalPart * $remStep !important;
  }

  .mb-#{$i}-#{$decimalPart}rem {
    margin-bottom: $i + $decimalPart * $remStep !important;
  }

  .ms-#{$i}-#{$decimalPart}rem {
    margin-left: $i + $decimalPart * $remStep !important;
  }
}


// ------------------------
// padding
// ------------------------
@for $i from 0 through 1000 {
  .p-#{$i}px {
    padding: $i * $pxStep !important;
  }

  .p-#{$i}rem {
    padding: $i * $remStep !important;
  }
}


// ------------------------
// padding px
// ------------------------
@for $i from 0 through 1000 {
  .pt-#{$i}px {
    padding-top: $i * $pxStep !important;
  }

  .pe-#{$i}px {
    padding-right: $i * $pxStep !important;
  }

  .pb-#{$i}px {
    padding-bottom: $i * $pxStep !important;
  }

  .ps-#{$i}px {
    padding-left: $i * $pxStep !important;
  }
}


// ------------------------
// padding rem
// ------------------------
@for $i from 0 through 1000 {
  .pt-#{$i}rem {
    padding-top: $i * $remStep !important;
  }

  .pe-#{$i}rem {
    padding-right: $i * $remStep !important;
  }

  .pb-#{$i}rem {
    padding-bottom: $i * $remStep !important;
  }

  .ps-#{$i}rem {
    padding-left: $i * $remStep !important;
  }
}


// ------------------------
// padding -X-
// ------------------------
@for $i from 0 through 1000 {
  .px-#{$i}px {
    padding-left: $i * $pxStep !important;
    padding-right: $i * $pxStep !important;
  }

  .px-#{$i}rem {
    padding-left: $i * $remStep !important;
    padding-right: $i * $remStep !important;
  }
}


// ------------------------
// padding -Y-
// ------------------------
@for $i from 0 through 1000 {
  .py-#{$i}px {
    padding-top: $i * $pxStep !important;
    padding-bottom: $i * $pxStep !important;
  }

  .py-#{$i}rem {
    padding-top: $i * $remStep !important;
    padding-bottom: $i * $remStep !important;
  }
}



// ------------------------
// padding px | float
// ------------------------
@for $i from 0 through 1000 {
  $decimalPart: $i / 10;

  .pt-#{$i}-#{$decimalPart}px {
    padding-top: $i + $decimalPart * $pxStep !important;
  }

  .pe-#{$i}-#{$decimalPart}px {
    padding-right: $i + $decimalPart * $pxStep !important;
  }

  .pb-#{$i}-#{$decimalPart}px {
    padding-bottom: $i + $decimalPart * $pxStep !important;
  }

  .ps-#{$i}-#{$decimalPart}px {
    padding-left: $i + $decimalPart * $pxStep !important;
  }
}


// ------------------------
// padding rem | float
// ------------------------
@for $i from 0 through 1000 {
  $decimalPart: $i / 10;

  .pt-#{$i}-#{$decimalPart}rem {
    padding-top: $i + $decimalPart * $remStep !important;
  }

  .pe-#{$i}-#{$decimalPart}rem {
    padding-right: $i + $decimalPart * $remStep !important;
  }

  .pb-#{$i}-#{$decimalPart}rem {
    padding-bottom: $i + $decimalPart * $remStep !important;
  }

  .ps-#{$i}-#{$decimalPart}rem {
    padding-left: $i + $decimalPart * $remStep !important;
  }
}


// ------------------------
// font-size
// ------------------------
@for $i from 0 through 1000 {
  .fs-#{$i}px {
    font-size: $i * $pxStep !important;
  }

  .fs-#{$i}rem {
    font-size: $i * $remStep !important;
  }
}


// ------------------------
// z-index
// ------------------------
@for $i from 0 through 9999 {
  .z-index-#{$i} {
    z-index: $i * $step !important;
  }
}


// ------------------------
// border-radius
// ------------------------
@for $i from 0 through 1000 {
  .radius-#{$i} {
    border-radius: $i * $percentStep !important;
  }

  .radius-#{$i}px {
    border-radius: $i * $pxStep !important;
  }
}


// ------------------------
// color / background color
// ------------------------
@each $color-name in $colors-list {
  .clr-#{$color-name} {
    color: var(--clr-#{$color-name}) !important;
  }

  .bg-#{$color-name} {
    background-color: var(--clr-#{$color-name}) !important;
  }
}


// ------------------------
// box-shadow
// ------------------------
@for $i from 0 through 10 {
  .shadow-#{$i} {
    box-shadow: var(--shadow-#{$i}) !important;
  }
}


// ------------------------
// letter-spacing
// ------------------------
@for $i from 0 through 100 {
  .letter-spacing-#{$i}px {
    letter-spacing: $i * $pxStep !important;
  }
}


// ------------------------
// top
// ------------------------
@for $i from 0 through 1000 {
  .top-#{$i}px {
    top: $i * $pxStep !important;
  }

  .top-#{$i}rem {
    top: $i * $remStep !important;
  }
}


// ------------------------
// right
// ------------------------
@for $i from 0 through 1000 {
  .right-#{$i}px {
    right: $i * $pxStep !important;
  }

  .right-#{$i}rem {
    right: $i * $remStep !important;
  }
}


// ------------------------
// bottom
// ------------------------
@for $i from 0 through 1000 {
  .bottom-#{$i}px {
    bottom: $i * $pxStep !important;
  }

  .bottom-#{$i}rem {
    bottom: $i * $remStep !important;
  }
}


// ------------------------
// left
// ------------------------
@for $i from 0 through 1000 {
  .left-#{$i}px {
    left: $i * $pxStep !important;
  }

  .left-#{$i}rem {
    left: $i * $remStep !important;
  }
}


// ------------------------
// hover
// ------------------------
@for $i from 1 through 10 {
  .hover-#{$i}-scale {
    transform: scale(0.1) !important;
    // transform: scale(1.#{$i}) !important;
  }
}


// ------------------------
// scale
// ------------------------
// @each $scale-value in $scale-list {
//   $formatted-value: str-slice("00" + $scale-value, -3);
//   .scale-#{$formatted-value} {
//     transform: scale(#{$scale-value}) !important;
//   }
// }


// ------------------------
// object-fit
// ------------------------
@each $fit-value in $object-fit-values {
  .object-fit-#{$fit-value} {
    object-fit: $fit-value !important;
  }
}