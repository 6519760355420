/* Nav bar */

.header-nav {
  background-color: #8bc1c1 !important;
  height: 80px !important;
  box-shadow: var(--shadow-1);
  position: relative !important;
  z-index: 99 !important;
}

.navbar-toggler {
  border: 1px dashed #564b8f85 !important;
  border-radius: 20% !important;
  height: 46px !important;
  width: 46px !important;
  box-shadow: var(--shadow-1);
  outline: none !important;
  transition: var(--transition);
}

.navbar-toggler:hover {
  border-radius: 50% !important;
  box-shadow: var(--shadow-x);
}

.navbar-collapse {
  transition: var(--transition) !important;
}


.navbar-toggler:focus {
  box-shadow: var(--shadow-1) !important;
}

.navbar-toggler-icon {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-18%, -2%) !important;
}

.user-header {
  margin: 1.7rem 0 0 0 !important;
}

.logo-nasnews {
  /* height: 55px; */
  transition: var(--transition);
  /* margin: -7px 0 0 0; */
  transform: scale(1.05);
}

@media screen and (max-width:640px) {
  .logo-nasnews {
    transform: scale(0.85);
  }
}

.col-categorie {
  margin: 0px -25px 0px 0px !important;
}

/* .logo-nasnews:hover {
  transform: scale(1.1);
} */

.navbar-nav a {
  font-family: var(--font-family-1) !important;
  color: var(--clr-light);
  letter-spacing: .5px;
  font-size: 25px !important;
  margin: 3px 0px 6px 0px !important;
  /* white-space: nowrap; */
}

/* .offcanvas-logo {
  height: 80px;
} */

@media screen and (max-width: 1199px) {
  .navbar-nav a {
    font-size: 25px !important;
    padding: 4px 5px !important;
    letter-spacing: normal;

  }
}

/* .navbar-nav a:hover {
  color: var(--clr-light) ;
} */

.navbar-icon {
  color: var(--clr-dark);
  font-size: 1.7rem !important;
  cursor: pointer;
  z-index: 999 !important;
  position: relative;
}

.navbar-icon:hover {
  color: var(--clr-blue) !important;
}

.icon-items {
  margin: 0 -3px 0 0px !important;
  z-index: 1;
  position: sticky;
}

.icon-items .row:nth-child(2) {
  margin: 0 -4rem !important;
}

@media screen and (min-width:992px) {
  .icon-items {
    margin: 0 -28px 0 0px !important;
  }



  /* .col-search{
    margin: 0 0 2rem 0 !important;
  } */

}

.navbar-toggler.collapsed {
  background-color: var(--clr-light) !important;
  transition: var(--transition) !important;
}

.user-account-icon {
  padding-right: 1.5rem !important;
  margin: 0 .6rem !important;
}


.dropdown-menu-categorie {
  width: 40rem !important;
  max-height: 47rem;
  overflow-y: scroll;
  padding: 1rem !important;
  z-index: 9999 !important;
  /* position: relative !important; */
  left: -40%;
}

.dropdown-menu-latestpost {
  width: 63rem !important;
  /* max-height: 50rem; */
  height: 17rem;
  /* overflow-y: scroll; */
  padding: 1rem !important;
  z-index: 9999 !important;
  left: -207%;
}

@media screen and (max-width: 1200px) {
  .dropdown-menu-latestpost {
    width: 51rem !important;
    left: -230%;
  }
}

.categorie-image-container {
  width: 200px !important;
  height: 120px !important;
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-2);
  overflow: hidden;
  padding: 0 !important;
}

.latestpost-image-container {
  width: 210px !important;
  height: 140px !important;
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-2);
  overflow: hidden;
  padding: 0 !important;
}

.search-contact {
  margin: -28px 85px 0 0;
}

/* .nav-item-latestpost-title {
  width: 205px !important;
} */

.nav-item-latestpost-title p {
  font-size: 14px;
  padding: .3rem 0 0rem 0;
  transition: var(--transition);
}



.nav-item-categorie-title {
  width: 205px !important;
}

.nav-item-categorie-title p {
  font-size: 14px;
  padding: .3rem 0 0rem 0;
  transition: var(--transition);
}

.nav-item-latestpost-title:hover p {
  color: var(--clr-blue);
}

@media screen and (max-width:578px) {
  .icon-items .row:nth-child(2) {
    margin: 0 0rem !important;
  }

  .user-account-icon {
    padding: 0 !important;
  }

  .categorie-image-container {
    width: 97% !important;
    height: 110px !important;
  }

  .search-contact {
    margin: -29px 56px 0 0 !important;
  }

  .navbar-icon {
    font-size: 1.7rem !important;
  }

  .navbar-toggler.collapsed {
    margin: 0 -15px 0 0 !important;
  }

  .logo-nasnews {
    margin: -18px 0 0 0;
  }

  .latestpost-image-container {
    padding: 14px !important;
    width: 100% !important;
  }

}

.scale-off {
  transform: scale(.9);
}

/* @media screen and (max-width:992px) {
  .navbar-brand {
    margin: 0 0 0 -10rem !important;
  }

  .navbar-toggler {
    margin: 0px 0px 0 6rem !important;
  }
} */


.badge-card {
  z-index: 9 !important;
}

.active-link {
  background-color: var(--clr-blue) !important;
  border-radius: var(--radius) !important;
  padding: 6px 10px !important;
  color: var(--clr-light) !important;
}

.active-link a {
  color: var(--clr-light) !important;
}

.navbar-link {
  transition: all .7s;
  border-radius: var(--radius) !important;
  /* padding: 3px 6px !important; */
}

.navbar-link:hover {
  background-color: var(--clr-blue) !important;
  color: var(--clr-light) !important;
}

@media screen and(max-width:1540px) {
  .navbar-link {
    padding: 3px 8px !important;
  }
}

.bar-icon::after {
  content: "" !important;
  position: absolute !important;
  height: 20px !important;
  width: 2px !important;
  color: var(--clr-dark) !important;
  background-color: var(--clr-dark) !important;
  margin: 8px 0 0 3px !important;
}



/* Dropdown Menu */

/* .navbar-nav .dropdown-menu.show {
  position: relative !important;
} */

@media screen and (max-width:768px) {
  /* .navbar-nav .dropdown-menu.show {
    display: contents !important;
  } */

  .dropdown-item {
    padding: 0px 10px !important;
  }

  .navbar-nav .sub-dropdown-item {
    margin: -10px 0px 0px 0px !important;
    color: var(--clr-blue) !important;
  }

  .nav-item-latestpost-title p,
  .nav-item-categorie-title p {
    margin: -15px 0 0 0 !important;
    color: var(--clr-dark) !important;

  }

  .navbar-toggler.collapsed {
    margin-right: 8px !important;
  }

}


@media screen and (max-width:578px) {
  .navbar-nav .dropdown-menu.show {
    max-width: 20rem !important;
    max-height: 36rem;
  }

  .navbar-toggler.collapsed {
    margin-right: 0px !important;
  }


}

/* ====================== */
.dropdown-menu {
  transition: var(--transition) !important;
}

.navbar-nav li.dropdown-submenu:hover>ul.dropdown-menu {
  display: block !important;
  position: absolute !important;
}

/* .navbar-nav li.dropdown-submenu:hover>ul.dropdown-menu {
  display: block !important;
  position: absolute !important;
} */



.dropdown-menu {
  border: none !important;
  border-top: 2px var(--clr-blue) solid !important;
  background-color: var(--clr-light) !important;
  box-shadow: var(--shadow-2) !important;
  margin: .0rem 0 0 0 !important;
}

.sub-dropdown-menu {
  margin: -2rem 0 0 15rem !important;
  position: absolute !important;
}


.dropdown-menu li {
  display: block !important;
  padding: 11px 20px 0px 20px !important;
  margin: 2px 0 -7px -8px !important;
}



/* .dropdown-menu li:not(:last-child)::after {
  content: "" !important;
  position: absolute !important;
  height: 1px !important;
  width: 90% !important;
  color: var(--clr-dark) !important;
  background-color: var(--clr-light-dark) !important;
  padding: 0px 0px 0 0;
} */

.dropdown-item {
  display: block !important;
  padding: 5px 10px !important;
  clear: both !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  background-color: transparent !important;
  border: 0 !important;
  transition: all .5s !important;
}


.dropdown-item:hover {
  background-color: var(--clr-blue) !important;
  /* color: var(--clr-light) !important; */
  border-radius: var(--radius) !important;
  border-bottom: 1px var(--clr-dark) solid !important;
}

.greater {
  right: 10px !important;
  position: absolute !important;
  opacity: .6 !important;
  transform: scale(.7) !important;
}

.nav-item-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  object-fit: cover !important;
  transition: var(--transition);
}

.nav-item-latestpost-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  object-fit: cover !important;
  transition: var(--transition);
}

.nav-item-image:hover,
.nav-item-latestpost-image:hover {
  transform: scale(1.3);
}


/* search-box */

.search-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  background: var(--clr-light);
  border-radius: 10px;
  box-shadow: var(--shadow-x);
  padding: 1rem;
  font-family: var(--font-family-2);
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.7s ease, height 0.7s ease, margin 0.7s ease, all 0.7s ease;
  margin: -2rem 0 2rem 0rem !important;
}

.search-box.show {
  opacity: 1;
  height: auto;
  max-height: 40rem;
  overflow: scroll;
  padding: 1rem;
  margin: 2rem 0 7rem 0rem !important;
}

.input-group-append {
  margin-top: -2.3rem;
  position: absolute;
  top: 100%;
}

.search-box-title {
  color: var(--gray-2);
  opacity: 0.8;
}


.display-all {
  border-radius: var(--radius) !important;
  /* width: 20rem !important; */
  width: auto !important;
  padding: .8rem 1.1rem !important;
  border: 0.15rem solid var(--clr-primary) !important;
  text-transform: uppercase !important;
  margin: 0 0px 0px 0 !important;
  color: var(--clr-primary) !important;
  background-color: var(--clr-light-primary) !important;
  transition: var(--transition) !important;
}

.display-all:hover {
  color: var(--clr-light) !important;
  background-color: var(--clr-primary) !important;
}


@media screen and (max-width:1190px) {
  /* .logo-nasnews {
    height: 56px;
  } */
}

@media screen and (min-width: 992px) and (max-width:1120px) {
  /* .logo-nasnews {
    margin-left: -25px !important;
  } */
}

/* 
@media screen and (max-width:1100px) {
  .navbar-nav a {
    font-size: 11px !important;
  }
}

@media screen and (max-width:1010px) {
  .navbar-nav a {
    font-size: 10px !important;
  }
} */


@media screen and (max-width: 992px) {

  .navbar-toggler.collapsed {
    margin: 0 0 0 0;
    right: -20px !important;
    position: absolute !important;
    z-index: 999 !important;
  }

  .navbar-toggler {
    /* margin: -24px 0 0 0 !important; */
    right: -20px !important;
    position: absolute;
    z-index: 999 !important;
  }

  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 8% !important;
  }

  .navbar-brand {
    margin: 0rem 0 0 -2rem !important;
    max-width: 275px !important;
  }

  .dropdown-menu li {
    padding: 11px 0px 0px 20px !important;
  }

  .dropdown-menu-categorie {
    width: 44rem !important;
  }


  .navbar-nav .dropdown-menu.show {
    position: absolute !important;
  }

  .nav-item-latestpost-title p,
  .nav-item-categorie-title p {
    margin: -15px 0 0 0 !important;
  }

  .nav-item-image,
  .nav-item-latestpost-image {
    transform: scale(1.1);
  }

  .navbar-link {
    padding: 3px 6px !important;
  }

  .dropdown-menu-categorie {
    width: 57rem !important;
  }

  /* .logo-nasnews {
    margin: -14px 0 10px 50px !important;
    height: 65px !important;
  } */

  /* @media screen and (max-width: 991px) { */
  .header-nav {
    height: auto !important;
  }

  .navbar-collapse {
    margin: 2rem 0 0 0 !important;
  }

  .user-header {
    position: absolute !important;
  }

  /* .navbar-nav a {
    color: var(--clr-dark) !important;
  } */

  /* 
  .navbar-nav a {
    font-size: 17px !important;
  } */



  /* .navbar-nav li.dropdown-menu:hover>ul.dropdown-menu {
          display: block !important;
          position: absolute !important;
        } */
}


@media screen and (max-width: 1200px) and (min-width:992px) {

  .search-col {
    top: 0px !important;
    position: absolute !important;
    left: 18% !important;
  }
}

@media screen and (max-width: 992px) {
  .dropdown-menu-categorie {
    width: 33rem !important;
  }

  .search-col {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 692px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 10% !important;
  }

}

@media screen and (max-width: 568px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 13% !important;

  }
}

@media screen and (max-width: 568px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 13% !important;

  }
}

@media screen and (max-width: 450px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 23% !important;

  }
}