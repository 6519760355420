.post-details-image {
    width: 100%;
    position: relative;
    height: 55vh;
}

.post-details-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: var(--transition);
}


.post-details-image:hover .post-details-img {
    transform: scale(1.1);
}

.truncate-text {
    direction: rtl;
    text-overflow: ellipsis;
}

.pagination-btn {
    border: 1px solid var(--clr-blue) !important;
    background-color: #f2faff !important;
    color: var(--clr-blue) !important;
    transition: var(--transition) !important;
}

.pagination-btn:hover {
    border: 1px solid var(--clr-blue) !important;
    background-color: var(--clr-blue) !important;
    color: var(--clr-light) !important;
}

.coment-container {
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}


.scale-wtsp {
    transform: scale(.93);
}