.card-header {
    position: relative;
}

.card-header::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    display: block;
    background-color: var(--clr-blue) !important;
    opacity: .1;
    width: 90%;
    height: 2px;
    transition: var(--transition);
    padding: 0 10px !important;
}

.card-header::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 1.5rem;
    display: block;
    background-color: var(--clr-danger) !important;
    width: 20%;
    height: 2px;
    transition: var(--transition);
    z-index: 1;
}