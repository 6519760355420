.submit-btn {
    color: var(--clr-blue) !important;
    font-size: 14px !important;
    background-color: var(--clr-blue-light) !important;
    border: 2px solid var(--clr-blue) !important;
    box-shadow: var(--shadow-2);
    padding: 10px 2rem !important;
    margin: -2rem 0 0 0 !important;
    margin-top: -2rem;
    text-transform: uppercase !important;
    letter-spacing: .8px !important;
    transition: var(--transition) !important;
}

.submit-btn:hover {
    background-color: var(--clr-dark) !important;
    color: var(--clr-light) !important;
    background-color: var(--clr-blue) !important;
}

.addtocart-icon {
    font-size: 20px !important;
    margin: 0px 9px 1px -7px !important;
    transform: scale(1.3) !important;
}