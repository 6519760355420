.special-post-image {
    width: 100%;
    position: relative;
    /* height: 100%; */
}

.special-post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: var(--transition);
}


.special-post-image:hover .special-post-img {
    transform: scale(1.1);
}