.post-card-image {
    width: 100%;
    position: relative;
    height: 55vh;
}

.post-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: var(--transition);
}


.post-card-image:hover .post-card-img {
    transform: scale(1.1);
}

.truncate-text {
    direction: rtl;
    text-overflow: ellipsis;
}

@media screen and (max-width:578px) {
    .post-card-image,
    .post-details-image {
        height: 25vh !important;
    }
}